.card {
  background: #2b55a1;
  color: #ffff;
  border-radius: 1rem;
  padding: 2.5rem 3rem;
  height: 100%;
  max-height: 32rem;
  .title {
    font-weight: bold;
  }
  .description {
    margin-top: 1.2rem;
    font-family: "Raleway";
    font-weight: 400;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
